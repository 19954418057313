import { useAtom } from "jotai";
import HeaderValues from "../jotai/header";
import { useEffect } from "react";

const Page = (props) => {
  const [hedaerTitle, setHeaderTitle] = useAtom(HeaderValues);

  useEffect(() => {
    setHeaderTitle({ title: "Dashboard" });
  }, []);

  return <div>Dashboard</div>;
};

export default Page;
