const main_url = "https://app.flipurapp.io";

export const login_endpoint = main_url + "/api/panel/v1/auth/login";

export const logout_endpoint = main_url + "/api/panel/v1/auth/logout";

// properties
export const projects_endpoint = main_url + "/api/panel/v1/property/";

// users
export const users_endpoint = main_url + "/api/panel/v1/users/";

export const investments_endpoint = main_url + "/api/panel/v1/investment/"