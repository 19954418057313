import axios from 'axios';
import dayjs from "dayjs";
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { projects_endpoint } from '../endpoints';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useReducer, useEffect, useMemo, useState } from 'react';
import { Form, Input, DatePicker, InputNumber, Select, Button, Upload, Space, Row, Col, Image, Typography, Spin } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

const initialState = {
  title: "",
  address: "",
  investment_start_at: null,
  investment_finish_at: null,
  share_cost: null,
  min_share_cnt: null,
  max_share_cnt: null,
  tenant_type: "",
  constructed_at: null,
  bedroom_cnt: null,
  area: null,
  floor_cnt: null,
  bathroom_cnt: null,
  description: "",
  gallery_images: [],
  unique_ids: [],
  cover_image: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SET_GALLERY_IMAGES':
      return { ...state, gallery_images: action.images };
    case 'SET_COVER_IMAGE':
      return { ...state, cover_image: action.image };
    case 'REMOVE_GALLERY_IMAGE':
      return { ...state, gallery_images: state.gallery_images.filter(image => image.uid !== action.uid) };
    case 'SET_PROPERTY_DATA':
      return { ...state, ...action.data };
    default:
      return state;
  }
};

const EditPropertyPage = () => {
  const params = useParams()
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false)
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const propertyId = useMemo(() => {
    return params.id
  }, [params])

  const token = useMemo(() => {
    return `Bearer ${localStorage.getItem("accesstoken")}`
  }, [])

  const fetchPropertyData = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(projects_endpoint + propertyId, {
        headers: {
          Authorization: token
        }
      });
      const { investment_finish_at, investment_start_at, constructed_at, cover_image, gallery_images, ...data } = response.data.result
      dispatch({
        type: 'SET_PROPERTY_DATA',
        data: {
          ...data,
          constructed_at: dayjs(constructed_at),
          investment_start_at: dayjs(investment_start_at),
          investment_finish_at: dayjs(investment_finish_at),
          unique_ids: gallery_images.map((image) => image.unique_id),
          cover_image: { uid: cover_image.unique_id, url: cover_image.url },
          gallery_images: gallery_images.map((image) => ({ uid: image.unique_id, url: image.url })),
        }
      });
      form.resetFields()
    } catch (error) {
      console.error('Failed to fetch property data:', error);
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    fetchPropertyData();
  }, []);

  const handleFinish = async (data) => {
    const formData = new FormData();
    formData.append("_method", 'PATCH')

    const forbiddenKeys = ['cover_image', 'gallery_images', ''];
    Object.entries(data).forEach(([key, value]) => {
      if (!forbiddenKeys.includes(key)) {
        formData.append(key, value)
      }
    })

    if (state.cover_image?.originFileObj) {
      formData.append("cover_image", state.cover_image.originFileObj)
    }

    if (state.gallery_images.length) {
      state.gallery_images.forEach((file) => {
        if (file?.originFileObj)
          formData.append("gallery_images[]", file.originFileObj)
      })
    }

    try {
      await axios.post(projects_endpoint + propertyId, formData, {
        headers: {
          Authorization: token,
          'Content-Type': 'multipart/form-data',
        }
      });
      toast.success("Successfully Updated!");
    } catch (error) {
      toast.error("Failed to update!");
    }
  };

  const removeImageByUid = async (unique_id) => {
    const payload = {
      unique_id,
      _method: 'delete'
    }

    try {
      await axios.post(`${projects_endpoint}${propertyId}/delete-image`, payload, {
        headers: {
          Authorization: token
        }
      })
    } catch (error) {
      console.error(error);
    }
  }

  const handleRemoveImage = async (file) => {
    if (state.unique_ids.includes(file.uid)) {
      await removeImageByUid(file.uid)
    }
    dispatch({ type: 'REMOVE_GALLERY_IMAGE', uid: file.uid });
  };

  const handlePreview = async (file) => {
    setPreviewOpen(true);
    setPreviewImage(file.url || file.thumbUrl);
  };

  const handleBeforeUpload = (file) => {
    const isSmallerThan3MB = file.size / 1024 / 1024 < 3;
    if (!isSmallerThan3MB) {
      toast.error('Image must be smaller than 3MB!');
    }
    return isSmallerThan3MB;
  };

  const handleCoverImageChange = ({ file: { error, response, ...file } }) => {
    dispatch({ type: "SET_COVER_IMAGE", image: { ...file, status: "uploaded" } })
  }

  const handleGalleryChange = ({ fileList }) => {
    if (fileList.length > 10) {
      toast.error('You can only upload up to 10 images.');
      return;
    }
    dispatch({
      type: 'SET_GALLERY_IMAGES', images: fileList.map(({ error, response, ...file }) => ({ ...file, status: "uploaded" }))
    });
  };

  if (isLoading)
    return (
      <Row justify={'center'}>
        <Spin />
      </Row>
    )

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={state}
      onFinish={handleFinish}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Cover Image" required rules={[{ required: true }]}>
            {state.cover_image && (
              <Upload
                maxCount={1}
                listType="picture-card"
                onPreview={handlePreview}
                fileList={[state.cover_image]}
                beforeUpload={handleBeforeUpload}
                onChange={handleCoverImageChange}
                showUploadList={{ showRemoveIcon: true }}>
                <Space>
                  <UploadOutlined />
                  <Typography>Upload</Typography>
                </Space>
              </Upload>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Gallery Images" >
            <Upload
              multiple
              maxCount={10}
              listType="picture-card"
              onPreview={handlePreview}
              onRemove={handleRemoveImage}
              onChange={handleGalleryChange}
              fileList={state.gallery_images}
              beforeUpload={handleBeforeUpload}
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}>
              <Space>
                <PlusOutlined />
                <Typography>Upload</Typography>
              </Space>
            </Upload>
          </Form.Item>
          {previewImage && (
            <Image
              src={previewImage}
              wrapperStyle={{ display: 'none', }}
              preview={{
                visible: previewOpen,
                onVisibleChange: setPreviewOpen,
                afterOpenChange: (visible) => { if (!visible) setPreviewImage('') },
              }}
            />
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please enter title' }]}>
            <Input value={state.title} onChange={(e) => dispatch({ type: "SET_FIELD", field: "title", value: e.target.value })} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Investment Start At" name="investment_start_at" rules={[{ required: true, message: 'Please select start date' }]}>
            <DatePicker onChange={(date) => dispatch({ type: 'SET_FIELD', field: 'investment_start_at', value: date })} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Investment Finish At" name="investment_finish_at" rules={[{ required: true, message: 'Please select finish date' }]}>
            <DatePicker onChange={(date) => dispatch({ type: 'SET_FIELD', field: 'investment_finish_at', value: date })} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Share Cost" name="share_cost" rules={[{ required: true, message: 'Please enter share cost' }]}>
            <InputNumber min={0} onChange={(value) => dispatch({ type: 'SET_FIELD', field: 'share_cost', value })} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Minimum Share Count" name="min_share_cnt" rules={[{ required: true, message: 'Please enter minimum share count' }]}>
            <InputNumber min={0} onChange={(value) => dispatch({ type: 'SET_FIELD', field: 'min_share_cnt', value })} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Maximum Share Count" name="max_share_cnt" rules={[{ required: true, message: 'Please enter maximum share count' }]}>
            <InputNumber min={0} onChange={(value) => dispatch({ type: 'SET_FIELD', field: 'max_share_cnt', value })} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tenant Type" name="tenant_type" rules={[{ required: true, message: 'Please select tenant type' }]}>
            <Select onChange={(value) => dispatch({ type: 'SET_FIELD', field: 'tenant_type', value })}>
              <Option value="family">Family</Option>
              <Option value="single">Single</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Constructed At" name="constructed_at" rules={[{ required: true, message: 'Please select constructed date' }]}>
            <DatePicker onChange={(date) => dispatch({ type: 'SET_FIELD', field: 'constructed_at', value: date })} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Bedroom Count" name="bedroom_cnt" rules={[{ required: true, message: 'Please enter bedroom count' }]}>
            <InputNumber min={0} onChange={(value) => dispatch({ type: 'SET_FIELD', field: 'bedroom_cnt', value })} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Area" name="area" rules={[{ required: true, message: 'Please enter area' }]}>
            <InputNumber min={0} onChange={(value) => dispatch({ type: 'SET_FIELD', field: 'area', value })} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Floor Count" name="floor_cnt" rules={[{ required: true, message: 'Please enter floor count' }]}>
            <InputNumber min={0} onChange={(value) => dispatch({ type: 'SET_FIELD', field: 'floor_cnt', value })} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Bathroom Count" name="bathroom_cnt" rules={[{ required: true, message: 'Please enter bathroom count' }]}>
            <InputNumber min={0} onChange={(value) => dispatch({ type: 'SET_FIELD', field: 'bathroom_cnt', value })} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <TextArea rows={4} value={state.description} onChange={(e) => dispatch({ type: 'SET_FIELD', field: 'description', value: e.target.value })} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Address" name="address" rules={[{ required: true }]}>
            <TextArea rows={4} value={state.description} onChange={(e) => dispatch({ type: 'SET_FIELD', field: 'address', value: e.target.value })} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default EditPropertyPage