import React, { useState } from "react";
import axios from "axios";
import { Button, Form, Input, Card } from "antd";

// ----------endpoints
import { login_endpoint } from "../endpoints";

import "../styles/login.css";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Login = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = async (values) => {
    try {
      setIsLoading(true)
      const response = await axios({ method: 'post', url: login_endpoint, data: values })
      localStorage.setItem("accesstoken", response.data.result.token);
      navigate('/')
      const message = response.data.message || 'You have successfully logged in'
      toast.success(message)
    } catch (error) {
      const message = error.response.data.message || 'Please try again later.'
      toast.error(message)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <Card className="login-card">
        <img
          alt="login"
          className="login-cover"
          src={require("../assets/images/logo.png")}
        />

        <Form
          name="basic"
          labelCol={{
            span: 12,
          }}
          //   wrapperCol={{
          //     span: 5,
          //   }}
          //   style={{
          //     maxWidth: 600,
          //   }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item style={{ width: "100%" }}>
            <Button style={{ width: "100%" }} type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
