import { useState } from "react";

import { Button, Layout, Menu, theme } from "antd";
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

import Header from "./Header";
import "../styles/sidebar.css";
import axios from "axios";
import { logout_endpoint } from "../endpoints";
import toast from "react-hot-toast";

const { Sider } = Layout;
const { Content } = Layout;

const headerItems = [
  { name: "Dashboard", route: "/" },
  { name: "Projects", route: "/projects" },
  { name: "Investments", route: "/investments" },
  { name: "Make Project", route: "/makeproject" },
  { name: "Users", route: "/users" },
];

const SideBarComponent = (props) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [activeItem, changeActiveItem] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const Logout = async () => {
    try {
      setIsLoading(true)
      const token = localStorage.getItem('accesstoken')
      await axios({
        method: 'post',
        url: logout_endpoint,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      localStorage.clear();
      navigate('/login');
      toast.success('Your logout was successful')
    } catch (error) {
      console.log(error);
      toast.error('There was a problem logging out')
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider width={280} trigger={null}>
        <div className="demo-logo-vertical">
          <img src={require("../assets/images/logo.png")} />
          <h5>Flipur</h5>
        </div>
        <Menu theme="dark">
          {headerItems.map((item, index) => {
            return (
              <Menu.Item className="sidebar-menu-item" key={index}>
                <NavLink
                  end
                  className={({ isActive }) =>
                    isActive
                      ? "secondHeader-item secondHeader-item-active"
                      : "secondHeader-item"
                  }
                  to={item.route}
                >
                  {item.name}
                </NavLink>
              </Menu.Item>
            );
          })}
        </Menu>

        <div className="sidebar-bottom">
          <div className="user-email">
            <UserOutlined />
            admin@gmail.com
          </div>
          <Button onClick={() => Logout()} loading={isLoading}>Log out</Button>
        </div>
      </Sider>

      <Layout>
        <Header
          {...{ collapsed }}
          {...{ setCollapsed }}
          {...{ colorBgContainer }}
        />

        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            overflow: "scroll",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default SideBarComponent;
