import { PlusOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Upload,
} from "antd";
import HeaderValues from "../jotai/header";
import axios from "axios";
import { projects_endpoint } from "../endpoints";
import toast from "react-hot-toast";
import {
  MapContainer,
  TileLayer,
  useMapEvents,
  Marker,
  Popup,
} from "react-leaflet";

// import 'leaflet/dist/leaflet.css';
// import './style.css';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const Page = (props) => {
  const params = useParams();
  const [fileList, setFileList] = useState([]);
  const [galleryList, setGalleryList] = useState([]);
  const [hedaerTitle, setHeaderTitle] = useAtom(HeaderValues);
  const [loading, changeLoading] = useState(false);
  const [location, setLocation] = useState([]);

  // ------- change header title
  useEffect(() => {
    setHeaderTitle({ title: "Make User" });
  }, []);

  const OnSubmit = async (val) => {
    changeLoading(true);
    const formData = new FormData();

    galleryList.map((item) => formData.append("gallery_images[]", item));
    formData.append("cover_image", fileList[0]);
    formData.append("title", val.title);
    formData.append("address", val.address);
    formData.append("investment_start_at", val.date[0]);
    formData.append("investment_finish_at", val.date[1]);
    formData.append("share_cost", val.share_cost);
    formData.append("min_share_cnt", val.min_share_cnt);
    formData.append("max_share_cnt", val.max_share_cnt);
    formData.append("tenant_type", val.tenant_type);
    formData.append("constructed_at", val.constructed_at);
    formData.append("bedroom_cnt", val.bedroom_cnt);
    formData.append("area", val.area);
    formData.append("floor_cnt", val.floor_cnt);
    formData.append("bathroom_cnt", val.bathroom_cnt);
    formData.append("description", val.description);

    // dont have map select
    formData.append("lat", 10.0);
    formData.append("long", 12.0);

    await axios({
      method: "post",
      url: projects_endpoint,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accesstoken"),
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        toast.success("Successfully create!");
        window.location.href = "/projects";
        changeLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Failed to create!");
        changeLoading(false);
      });
  };

  // ---------------- map

  return (
    <>
      <Form
        onFinish={(val) => {
          OnSubmit(val);
        }}
        labelWrap
        layout="vertical"
      >
        <Row>
          <Col span={12}>
            <Form.Item
              style={{ padding: 5 }}
              label="Fullname"
              name="fullname"
              rules={[
                {
                  required: true,
                  message: "Fullname is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              style={{ padding: 5 }}
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Username is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              style={{ padding: 5 }}
              label="Role"
              name="role"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              style={{ padding: 5 }}
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button loading={loading} htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Page;
