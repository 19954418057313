import React, { useState } from "react";
import { Layout, theme } from "antd";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// ------------ components
import { Header, Sidebar } from "./components";
// ------------ screens
import {
  Dashboard,
  MakeProject,
  Users,
  Projects,
  Login,
  EditProject,
  MakeUser
} from "./screens";
import InvestmentsPage from "./screens/Investments";

const { Content } = Layout;

const ProtectedRoute = ({ children }) => {
  if (!localStorage.getItem("accesstoken")) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const App = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Router>
      <Layout style={{ height: "100vh" }}>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Sidebar />
              </ProtectedRoute>
            }
          >
            <Route path="/" element={<Dashboard />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/investments" element={<InvestmentsPage />} />
            <Route path="/editproject/:id" element={<EditProject />} />
            <Route path="/makeproject" element={<MakeProject />} />
            <Route path="/makeuser" element={<MakeUser />} />
            <Route path="/Users" element={<Users />} />
          </Route>

          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
