import { PlusOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Upload,
} from "antd";
import HeaderValues from "../jotai/header";
import axios from "axios";
import { projects_endpoint } from "../endpoints";
import toast from "react-hot-toast";
import {
  MapContainer,
  TileLayer,
  useMapEvents,
  Marker,
  Popup,
} from "react-leaflet";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const Page = (props) => {
  const [fileList, setFileList] = useState([]);
  const [galleryList, setGalleryList] = useState([]);
  const [hedaerTitle, setHeaderTitle] = useAtom(HeaderValues);
  const [loading, changeLoading] = useState(false);

  // ------- change header title
  useEffect(() => {
    setHeaderTitle({ title: "Make Project" });
  }, []);

  // -------------- function to upload
  const props_upload = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const gallery_props_upload = {
    onRemove: (file) => {
      const index = galleryList.indexOf(file);
      const newFileList = galleryList.slice();
      newFileList.splice(index, 1);
      setGalleryList(newFileList);
    },
    beforeUpload: (file) => {
      setGalleryList([...galleryList, file]);
      return false;
    },
    galleryList,
  };

  const OnSubmit = async (val) => {
    changeLoading(true);
    const formData = new FormData();

    galleryList.forEach((item) => {
      formData.append("gallery_images[]", item);
    });

    formData.append("cover_image", fileList[0]);
    formData.append("title", val.title);
    formData.append("address", val.address);
    formData.append("investment_start_at", val.date[0]);
    formData.append("investment_finish_at", val.date[1]);
    formData.append("share_cost", val.share_cost);
    formData.append("min_share_cnt", val.min_share_cnt);
    formData.append("max_share_cnt", val.max_share_cnt);
    formData.append("tenant_type", val.tenant_type);
    formData.append("constructed_at", val.constructed_at);
    formData.append("bedroom_cnt", val.bedroom_cnt);
    formData.append("area", val.area);
    formData.append("floor_cnt", val.floor_cnt);
    formData.append("bathroom_cnt", val.bathroom_cnt);
    formData.append("description", val.description);

    // dont have map select
    formData.append("lat", 10.0);
    formData.append("long", 12.0);

    await axios({
      method: "post",
      url: projects_endpoint,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accesstoken"),
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        toast.success("Successfully create!");
        window.location.href = "/projects";
        changeLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.response.data.message);
        changeLoading(false);
      });
  };

  // ---------------- map
  // iconesh bayad dorost she :D
  const [position, changePosition] = useState([10, 20]);

  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        changePosition([e.latlng.lat, e.latlng.lng]);
        map.flyTo([e.latlng.lat, e.latlng.lng], map.getZoom());
        console.log("click", e);
        map.locate();
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>You are here</Popup>
      </Marker>
    );
  };

  return (
    <>
      <Form
        onFinish={(val) => {
          OnSubmit(val);
        }}
        labelWrap
        layout="vertical"
      >
        <Row>
          <Col span={12}>
            <Form.Item
              name="cover_image"
              style={{ padding: 5 }}
              label="Cover"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                {...props_upload}
                listType="picture-card"
                onPreview={() => {}}
                maxCount={1}
              >
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Upload
                  </div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              style={{ padding: 5 }}
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Title is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Investment timing"
              name="date"
              style={{ padding: 5 }}
              rules={[
                {
                  required: true,
                  message: "Investment Timing is required",
                },
              ]}
            >
              <RangePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              style={{ padding: 5 }}
              rules={[
                {
                  required: true,
                  message: "Share Cost is required",
                },
              ]}
              label="Share cost"
              name="share_cost"
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              style={{ padding: 5 }}
              rules={[
                {
                  required: true,
                  message: "min_share_cnt is required",
                },
              ]}
              label="min_share_cnt"
              name="min_share_cnt"
            >
              <InputNumber style={{ width: "100%" }} type="number" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              style={{ padding: 5 }}
              label="max_share_cnt"
              name="max_share_cnt"
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              style={{ padding: 5 }}
              rules={[
                {
                  required: true,
                  message: "tenant_type is required",
                },
              ]}
              label="Tenant type"
              name="tenant_type"
            >
              <Select>
                <Select.Option value="single">Single</Select.Option>
                <Select.Option value="family">Family</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              style={{ padding: 5 }}
              label="Constructed at"
              name="constructed_at"
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item style={{ padding: 5 }} label="Living Area" name="area">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              style={{ padding: 5 }}
              label="Bedrooms"
              name="bedroom_cnt"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item style={{ padding: 5 }} label="Floor" name="floor_cnt">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              style={{ padding: 5 }}
              label="Bathroom"
              name="bathroom_cnt"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              style={{ padding: 5 }}
              label="Description"
              name="description"
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item style={{ padding: 5 }} label="Address" name="address">
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              name="gallery_images"
              style={{ padding: 5 }}
              label="Gallery (max 10 images - each image less than 2MB)"
              valuePropName="galleryList"
              getValueFromEvent={normFile}
            >
              <Upload
                {...gallery_props_upload}
                listType="picture-card"
                onPreview={() => {}}
                maxCount={5}
              >
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Upload
                  </div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        {/* <Row>
          <div
            style={{
              height: 200,
              marginTop: 10,
              marginBottom: 10,
              width: "100%",
              overflow: "hidden",
              border: "solid 1px #EAEAEA",
            }}
          >
            <MapContainer center={[51.505, -0.09]} zoom={13}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker />
            </MapContainer>
          </div>
        </Row> */}

        <Form.Item>
          <Button loading={loading} htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Page;
