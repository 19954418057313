import axios from "axios";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { Space, Table, Modal, Button } from "antd";
import { investments_endpoint, projects_endpoint } from "../endpoints";

const { Column } = Table;

const InvestmentsPage = () => {
    const [open, setOpen] = useState(false);
    const [curItem, setCurItem] = useState({});
    const [investments, setInvestments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState();
    const [confirmLoading, setConfirmLoading] = useState(false);

    useEffect(() => {
        getInvestments();
    }, []);

    const getInvestments = async (page_val) => {
        const page = page_val | 1;
        setIsLoading(true);

        await axios({
            method: "get",
            url: investments_endpoint,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("accesstoken"),
            },
            params: {
                page,
            },
        })
            .then((res) => {
                const { pagination, data: investments } = res.data.result
                const data = investments.map((item) => ({
                    ...item.property,
                    amount: item.amount,
                    user_id: item.user_id,
                    investment_id: item.id,
                    email: item.user.email,
                    full_name: item.user.full_name,
                    cellphone: item.user.cellphone,
                }))
                setInvestments(data);
                setTotalPages(pagination.total);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const showModal = (item) => {
        setCurItem(item);
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
        setCurItem();
    };

    const handleOk = async () => {
        setConfirmLoading(true);

        await axios({
            method: "delete",
            url: investments_endpoint + curItem?.investment_id,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("accesstoken"),
            },
        })
            .then((res) => {
                toast.success("item deleted successfully!");
                setConfirmLoading(false);
                getInvestments();
                handleCancel();
            })
            .catch((err) => {
                toast.error("failed to delete!");
                setConfirmLoading(false);
                handleCancel();
                console.log("err", err);
            });
    };

    return (
        <>
            <Table
                dataSource={investments}
                loading={isLoading}
                pagination={{
                    defaultPageSize: 10,
                    total: totalPages,
                    onChange: (page) => {
                        getInvestments(page);
                    },
                }}
            >
                <Column
                    title="Cover"
                    key="cover_image"
                    dataIndex="cover_image"
                    render={(cover_image) => (
                        <img style={{ height: 100 }} src={cover_image.url} alt="cover" />
                    )}
                />
                <Column title="Name" dataIndex="full_name" key="full_name" />
                <Column title="Email" dataIndex="email" key="email" />
                <Column title="Mobil number" dataIndex="cellphone" key="cellphone" />
                <Column title="Title" dataIndex="title" key="title" />
                <Column title="Description" dataIndex="description" key="description" />
                <Column title="Amount" dataIndex="amount" key="amount" />
                <Column
                    title="Action"
                    key="action"
                    render={(_, record) => (
                        <Space size="middle">
                            <Button onClick={() => showModal(record)} className="table-operation-delete">
                                Delete
                            </Button>
                        </Space>
                    )}
                />
            </Table>

            <Modal
                title={"Delete " + curItem?.title}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText="Yes"
            >
                <p>Are you sure?</p>
            </Modal>
        </>
    );
};

export default InvestmentsPage;
