import { useEffect, useState } from "react";
import { Space, Table, Modal } from "antd";
import axios from "axios";
import { useAtom } from "jotai";
import HeaderValues from "../jotai/header";
import { projects_endpoint } from "../endpoints";
import { Link } from "react-router-dom";

// -----------style
import "../styles/projects.css";
import { toast } from "react-hot-toast";

const { Column } = Table;

const Page = (props) => {
  const [projects, changeProjects] = useState([]);
  const [loading, changeLoading] = useState(true);
  const [totalpages, changeTotalPages] = useState();
  const [open, setOpen] = useState(false);
  const [curItem, setCurItem] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [hedaerTitle, setHeaderTitle] = useAtom(HeaderValues);

  useEffect(() => {
    GetProjects();
    setHeaderTitle({ title: "Projects" });
  }, []);

  const GetProjects = async (page_val) => {
    let the_page = page_val | 1;
    changeLoading(true);
    await axios({
      method: "get",
      url: projects_endpoint,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accesstoken"),
      },
      params: {
        page: the_page,
      },
    })
      .then((res) => {
        changeProjects(res.data.result.data);
        changeTotalPages(res.data.result.pagination.total);
        changeLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // -------------- delete modal
  const showModal = (item) => {
    setCurItem(item);
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
    setCurItem();
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await axios({
      method: "delete",
      url: projects_endpoint + curItem?.id,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accesstoken"),
      },
    })
      .then((res) => {
        toast.success("item deleted successfully!");
        setConfirmLoading(false);
        GetProjects();
        handleCancel();
      })
      .catch((err) => {
        toast.error("failed to delete!");
        setConfirmLoading(false);
        handleCancel();
        console.log("err", err);
      });
  };

  return (
    <>
      <Table
        dataSource={projects}
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          total: totalpages,
          onChange: (page) => {
            GetProjects(page);
          },
        }}
      >
        <Column
          title="Cover"
          dataIndex="cover_image"
          key="cover_image"
          render={(cover_image) => (
            <img style={{ height: 100 }} src={cover_image.url} alt="cover" />
          )}
        />
        <Column title="Title" dataIndex="title" key="title" />
        <Column title="Description" dataIndex="description" key="description" />

        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <Link
                className="table-operation-edit"
                to={"/editproject/" + record.id}
              >
                Edit
              </Link>
              <a
                onClick={() => showModal(record)}
                className="table-operation-delete"
              >
                Delete
              </a>
            </Space>
          )}
        />
      </Table>

      <Modal
        title={"Delete " + curItem?.title}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Yes"
      >
        <p>Are you sure?</p>
      </Modal>
    </>
  );
};

export default Page;
