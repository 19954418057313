import { useEffect, useState } from "react";
import { Space, Table, Modal } from "antd";
import { useAtom } from "jotai";
import HeaderValues from "../jotai/header";
import axios from "axios";
import { Link } from "react-router-dom";

import { users_endpoint } from "../endpoints";
import { toast } from "react-hot-toast";

const { Column } = Table;

const Page = (props) => {
  const [users, changeUsers] = useState([]);
  const [totalpages, changeTotalPages] = useState();
  const [open, setOpen] = useState(false);
  const [curItem, setCurItem] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [hedaerTitle, setHeaderTitle] = useAtom(HeaderValues);
  const [loading, changeLoading] = useState(true);

  useEffect(() => {
    GetUsers();
    setHeaderTitle({ title: "Users" });
  }, []);

  const GetUsers = async (page_val) => {
    let the_page = page_val | 1;
    changeLoading(true);
    await axios({
      method: "get",
      url: users_endpoint,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accesstoken"),
      },
      params: {
        page: the_page,
      },
    })
      .then((res) => {
        changeUsers(res.data.result.data);
        changeTotalPages(res.data.result.pagination.total);
        changeLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // -------------- delete modal
  const showModal = (item) => {
    setCurItem(item);
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
    setCurItem();
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await axios({
      method: "delete",
      url: users_endpoint + curItem?.id,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accesstoken"),
      },
    })
      .then((res) => {
        toast.success("item deleted successfully!");
        setConfirmLoading(false);
        GetUsers();
        handleCancel();
      })
      .catch((err) => {
        toast.error("failed to delete!");
        setConfirmLoading(false);
        handleCancel();
        console.log("err", err);
      });
  };

  return (
    <>
      <Table
        dataSource={users}
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          total: totalpages,
          onChange: (page) => {
            GetUsers(page);
          },
        }}
      >
        <Column
          title="Image"
          dataIndex=""
          key="cover"
          render={(cover_image) => (
            <img
              style={{
                height: 50,
                borderRadius: 100,
                border: "solid 2px #333",
              }}
              src={require("../assets/images/profile.jpg")}
              alt="profile"
            />
          )}
        />
        <Column title="Full name" dataIndex="full_name" key="full_name" />
        <Column title="CellPhone" dataIndex="cellphone" key="cellphone" />

        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              {/* <Link
                className="table-operation-edit"
                to={"/editproject/" + record.id}
              >
                Edit
              </Link> */}
              <a
                onClick={() => showModal(record)}
                className="table-operation-delete"
              >
                Delete
              </a>
            </Space>
          )}
        />
      </Table>

      <Modal
        title={"Delete " + curItem?.full_name}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Yes"
      >
        <p>Are you sure?</p>
      </Modal>
    </>
  );
};

export default Page;
